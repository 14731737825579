import Axios from 'axios';
import Helper from './helper';
import tmi from 'tmi.js';
import Storage from './storage';

class Twitch {
  static getIdBychannelName = async (channel) => {
    const cacheInformation = Storage.get(channel);
    if (cacheInformation) {
      return cacheInformation;
    }
    const twitchAPIUlr = this.getApiEndpoint();
    const restTwitch = await Axios.get(
      `${twitchAPIUlr}/helix/users?login=${channel}`,
      {
        headers: {
          'Client-Id': process.env.REACT_APP_TWITCH_API_CLIENT_ID,
          Authorization: `Bearer ${process.env.REACT_APP_TWITCH_API_TOKEN}`,
        },
      },
    );

    const data = restTwitch?.data?.data;

    if (!data || !data.length || !data[0]) {
      console.log('data not found :(');
      return;
    }
    Storage.set(channel, data[0].id);
    return data[0].id;
  };

  static getApiEndpoint = () => {
    return Helper.isProductionEnvironment()
      ? process.env.REACT_APP_TWITCH_API_URL
      : '';
  };

  static getEmotesLinks = (emotes) => {
    let result = [];
    Object.entries(emotes).forEach(([id, positions]) => {
      result = result.concat(
        positions.map(
          () =>
            `https://static-cdn.jtvnw.net/emoticons/v2/${id}/default/dark/3.0`,
        ),
      );
    });

    return result;
  };

  static getEmotesByTags = (tags) => {
    if (tags.emotes && tags['message-type'] === 'chat') {
      return this.getEmotesLinks(tags.emotes);
    }

    return [];
  };

  static onConnectedHandler = (addr, port) => {
    console.log(`* Connected to ${addr}:${port}`);
  };

  static connectToChat = (channel, onMessageHandler) => {
    const opts = {
      channels: [channel],
    };

    const client = new tmi.client(opts);
    client.on('message', onMessageHandler);
    client.on('connected', this.onConnectedHandler);
    client.connect();
  };
}

export default Twitch;
