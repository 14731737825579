class Storage {
  static saveWithExpiration = (key, value, expirationDays) => {
    expirationDays = expirationDays || 5;
    const now = new Date();
    let expDate = new Date();
    expDate.setDate(now.getDate() + expirationDays);

    const item = {
      value: value,
      expiry: expDate,
    };

    localStorage.setItem(key, JSON.stringify(item));
  };

  static getWithExpiration = (key) => {
    const data = localStorage.getItem(key);
    if(!data) {
      return undefined;
    }

    const item = JSON.parse(data);
    const now = new Date();
    const expDate = new Date(item.expiry);
    if (expDate < now) {
      localStorage.removeItem(key);
      return undefined;
    }

    return item.value;
  };
  static set(key, value) {
    window.localStorage.removeItem(key);
    window.localStorage.setItem(key, JSON.stringify(value));
  }
  static get(key) {
    return JSON.parse(localStorage.getItem(key));
  }
}

export default Storage;
