import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Overlay from './pages/Overlay';
import { MyIntlProvider } from './context/IntlContext';

function App() {
  return (
    <MyIntlProvider>
      <div className="root">
        <Router>
          <Routes>
            <Route path="/*" element={<Home />} />
            <Route path="/overlay/" element={<Overlay />} />
          </Routes>
        </Router>
      </div>
    </MyIntlProvider>
  );
}

export default App;
