import './GenerateLinkForm.css';
import { useState, React } from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';

function GenerateLinkForm() {
  var CryptoJS = require('crypto-js');
  const key = process.env.REACT_APP_HASH_KEY;
  const [textAnimation, setTextAnimation] = useState();
  const [channel, setChannel] = useState();
  const [overlayLink, setoverlayLink] = useState('');
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
  const rootUrl = window.location.origin;
  const intl = useIntl();

  const getOverlaylink = () => {
    if (!channel) {
      setTextAnimation('animated shake');
      setTimeout(() => {
        setTextAnimation('');
      }, 1000);
      return;
    }
    const hash = CryptoJS.HmacSHA256(channel, key).toString();
    const link = `${rootUrl}/overlay/?utm_source=${channel}&utm_medium=overlay&utm_campaign=memotewall&channel=${channel}&hash=${hash}`;
    setoverlayLink(link);
  };

  const copyToClipboard = () => {
    navigator.clipboard?.writeText(overlayLink);
    setShowCopiedTooltip(true);
    setTimeout(() => setShowCopiedTooltip(false), 2000);
  };

  return (
    <div
      className="generate-link-form__container"
      data-testid="generate-link-form"
    >
      <input
        maxLength={35}
        className={textAnimation}
        onChange={(event) => setChannel(event.target.value)}
        placeholder={intl.formatMessage({
          id: 'GenerateLinkForm.placeHolder',
        })}
      />
      <button title="generate" onClick={getOverlaylink}>
        <FormattedMessage id="GenerateLinkForm.go" />
      </button>
      {overlayLink ? (
        <>
          <input
            type="text"
            className="animated fadeInUp"
            value={overlayLink}
            readOnly={true}
          />

          <div className="generate-link-form__copied-tooltip">
            {showCopiedTooltip ? (
              <span className="animated fadeInUp">
                <FormattedMessage id="GenerateLinkForm.copied" />{' '}
              </span>
            ) : null}
          </div>

          <button
            className="animated fadeInUp generate-link-form__copy-button"
            title="copy"
            onClick={copyToClipboard}
          >
            <FormattedMessage id="GenerateLinkForm.copy" />
          </button>
        </>
      ) : null}
    </div>
  );
}

export default GenerateLinkForm;
