import React from 'react';
import './Footer.css';
import instagramIcon from '../assets/icons/instagram_icon.png';
import twitterIcon from '../assets/icons/twitter_icon.png';
import twitchIcon from '../assets/icons/twitch_icon.png';
import { Animated } from 'react-animated-css';

function Footer() {
  return (
    <div className="footer__container" data-testid="footer">
      <div className="footer__follow">
        <div className="footer__follow__icons">
          <Animated animationInDelay={1000} animationIn="fadeInUp">
            <a
              href="https://www.instagram.com/jmemo162/"
              alt="instagram"
              target={'_blank'}
              rel="noreferrer"
            >
              <img
                className="footer__follow__icons__icon"
                src={instagramIcon}
                alt="instagram"
              />
            </a>
          </Animated>
          <Animated animationInDelay={1200} animationIn="fadeInUp">
            <a
              href="https://www.twitch.tv/jmemo162"
              alt="twitch"
              target={'_blank'}
              rel="noreferrer"
            >
              <img
                className="footer__follow__icons__icon"
                src={twitchIcon}
                alt="twitch"
              />
            </a>
          </Animated>
          <Animated animationInDelay={1400} animationIn="fadeInUp">
            <a
              href="https://twitter.com/jmemo162"
              alt="twitter"
              target={'_blank'}
              rel="noreferrer"
            >
              <img
                className="footer__follow__icons__icon"
                src={twitterIcon}
                alt="twitter"
              />
            </a>
          </Animated>
        </div>
      </div>
      <div className="footer__powered-by">
        <span>
          Made with &#10084; by <b>Jmemo162</b> © 2022
        </span>
      </div>
    </div>
  );
}

export default Footer;
