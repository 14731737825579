import React from 'react';
import './HowItWorks.css';
import { FormattedMessage } from 'react-intl';

const HowItWorks = () => {
  return (
    <div id="how-to-use" className="how-it-works">
      <h1 className="how-it-works-title">
        <FormattedMessage id="HowItWorks.tittle" />
      </h1>
      <p className="intro">
        <FormattedMessage id="HowItWorks.intro" />
        <span className="bold-800"> Jmemo162 :)</span>
      </p>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/wzPTw_hNGzE?si=i_hJuzDZ5W-cga13"
          title="YouTube video player"
          frameBorder="0"
          style={{ border: '8px solid #fff' }}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className="numbered-paragraphs">
        <ol>
          <li>
            <p>
              <FormattedMessage id="HowItWorks.description1" />
            </p>
          </li>
          <li>
            <p>
              <FormattedMessage id="HowItWorks.description2" />
            </p>
            <p>
              <FormattedMessage id="HowItWorks.description2Link" />
            </p>
          </li>
          <li>
            <p>
              <FormattedMessage id="HowItWorks.description3" />
            </p>
            <ul>
              <li className="disc">
                <FormattedMessage id="HowItWorks.description3A" />
              </li>
              <li className="disc">
                <FormattedMessage id="HowItWorks.description3B" />
              </li>
              <li className="disc">
                <FormattedMessage id="HowItWorks.description3C" />
              </li>
            </ul>
          </li>
          <li>
            <p>
              <span className="bold-700">
                <FormattedMessage id="HowItWorks.description4" />
              </span>
            </p>
            <ul>
              <li className="disc">
                <FormattedMessage id="HowItWorks.description4A" />
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default HowItWorks;
