class Emote {
  static getEmoteLink = (id, baseUrl) => {
    return `${baseUrl}${id}`;
  };

  static getMappedEmotes = (emotes, baseUrl, key = 'code', id = 'id') => {
    if (!emotes) return;

    let objectOut = {};
    emotes.forEach((emote) => {
      objectOut[emote[key]] = this.getEmoteLink(emote[id], baseUrl);
    });
    return objectOut;
  };

  static getEmotesByMessage = (message, emotesObject) => {
    let emotesFound = [];
    if (message && message.length > 1 && emotesObject) {
      message.split(' ').forEach((word) => {
        if (word.trim().length > 1 && emotesObject[word.trim()]) {
          emotesFound.push(`${emotesObject[word.trim()]}/3x.webp`);
        }
      });
    }

    return emotesFound;
  };
}

export default Emote;
