import React from 'react';
import './WhatIsThis.css';
import { FormattedMessage } from 'react-intl';

function WhatIsThis() {
  return (
    <div id="wtf-is-this" className="what-is-this">
      <h2 className="wtf-title">
        <FormattedMessage id="WhatIsThis.tittle" />
      </h2>
      <h4 className="wtf-title2">
        <FormattedMessage id="WhatIsThis.tittle2" />
      </h4>
      <p className="wtf-description">
        <FormattedMessage id="WhatIsThis.description" />
      </p>
    </div>
  );
}

export default WhatIsThis;
