import { useEffect, useState, React } from 'react';
import { Animated } from 'react-animated-css';
import PropTypes from 'prop-types';
import Animation from '../utils/animation';
import './Emoji.css';

const Emoji = ({ link, id }) => {
  const _animationInDuration = 2000;
  const _animationOutDuration = 2000;
  const _emojiVisibleTime = 5000;
  const animatioDuration = Animation.getAnimationDurationTime(
    _animationInDuration,
    _animationOutDuration,
    _emojiVisibleTime,
  );
  const sizes = ['36px', '40px', '56px'];
  const [visible, setVisible] = useState(true);
  const [style, setstyle] = useState({
    animationIterationCount: 1,
    bottom: `${Math.random() * 90}%`,
    left: `${Math.random() * 100}%`,
    position: 'fixed',
    width: '36px',
    height: '36px',
  });
  const [imgStyle, setImgStyle] = useState();
  const [constainerStyle, setConstainerStyle] = useState();
  const handlerImageError = () => {
    setstyle({
      display: 'none',
    });
    setVisible(false);
  };

  useEffect(() => {
    const imgSize = sizes[Math.floor(Math.random() * sizes.length)];

    setTimeout(() => {
      setVisible(false);
    }, _emojiVisibleTime);

    setstyle((state) => ({
      ...state,
      ...Animation.getRandom(animatioDuration),
      width: imgSize,
      height: imgSize,
    }));

    setImgStyle(() => ({
      width: imgSize,
      height: imgSize,
    }));

    setConstainerStyle(Animation.getContainerStyle(animatioDuration));

    return () => {
      setVisible();
    };
  }, []);

  return (
    <div style={constainerStyle} className="emoji-container">
      <div style={style} data-testid="emoji-container">
        <Animated
          animationInDuration={_animationInDuration}
          animationOutDuration={_animationOutDuration}
          animationIn="bounceIn"
          animationOut="fadeOut"
          isVisible={visible}
        >
          <img
            src={link}
            alt={id}
            style={imgStyle}
            data-testid="emoji"
            onError={handlerImageError}
          />
        </Animated>
      </div>
    </div>
  );
};

Emoji.propTypes = {
  link: PropTypes.string,
  id: PropTypes.number,
};
export default Emoji;
