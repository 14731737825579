import React from 'react';
import './AboutUs.css';
import Teamate from './Teamate/Teamate';
import jmemo_image from '../../assets/images/teniente_dan.png';
import nana_image from '../../assets/images/nana1.jpg';
import danizulu_image from '../../assets/images/danizulu.jpeg';
import angela_image from '../../assets/images/angela.png';
import { FormattedMessage } from 'react-intl';

function AboutUs() {
  return (
    <div id="about-us">
      <h1 className="about-us__title">
        <FormattedMessage id="AboutUs.tittle" />
      </h1>
      <div className="about-us__team__container">
        <Teamate
          name="@angelaEspinosa2021"
          link="https://github.com/AngelaEspinosa2021"
          image={angela_image}
        ></Teamate>
        <Teamate
          name="@danielaespinosa_ph"
          link="https://www.instagram.com/danielaespinosa_ph/"
          image={nana_image}
        ></Teamate>
        <Teamate
          name="@danizulu7"
          link="https://github.com/danizulu7"
          image={danizulu_image}
        ></Teamate>
        <Teamate
          name="@jmemo162"
          link="https://www.instagram.com/jmemo162/"
          image={jmemo_image}
        ></Teamate>
      </div>
      <p className="about-us__caption">
        <FormattedMessage id="AboutUs.caption1" />
        <br/><br/>
        <FormattedMessage id="AboutUs.caption2" />
      </p>
    </div>
  );
}

export default AboutUs;
