import Axios from 'axios';
import Twitch from './twitch';
import Storage from './storage';
import Emote from './emote';

class SevenTv {
  static _cachedEmotesId = 'cachedEmotes7tv';
  static _baseUrlCDN = process.env.REACT_APP_7TV_CDN_URL;

  static getEmotes = async (channel) => {
    const cachedEmotes = Storage.getWithExpiration(this._cachedEmotesId);
    if (cachedEmotes) {
      return cachedEmotes;
    }

    const idTwitch = await Twitch.getIdBychannelName(channel);
    if (!idTwitch) {
      return;
    }

    const sevenTvAPIUrl = process.env.REACT_APP_7TV_API_URL;
    const result = await Axios.get(
      `${sevenTvAPIUrl}/v3/users/twitch/${idTwitch}`,
    );
    const mappedEmotes = Emote.getMappedEmotes(
      result.data.emote_set?.emotes,
      this._baseUrlCDN,
      'name'
    );
    Storage.saveWithExpiration(this._cachedEmotesId, mappedEmotes);
    return mappedEmotes;
  };
}
export default SevenTv;
