class Helper {
  static isProductionEnvironment = () => {
    return !this.isDevelopEnvironment();
  };

  static isDevelopEnvironment = () => {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  };

  static getRadomSize = () => {
    return Math.floor(Math.random() * 3) + 1;
  };

  static validateHash = (channel, hash) => {
    var CryptoJS = require('crypto-js');
    const calculatedHashByChannel = CryptoJS.HmacSHA256(
      channel,
      process.env.REACT_APP_HASH_KEY,
    ).toString();
    return hash === calculatedHashByChannel;
  };

  static clearArraByMaxLength = (array, maxLength) => {
    if (array?.length > maxLength) {
      return [];
    }
    return array;
  };
}

export default Helper;
