import Axios from 'axios';
import Helper from './helper';
import Twitch from './twitch';
import Storage from './storage';
import Emote from './emote';

class BTTV {
  static _cachedEmotesId = 'cachedEmotesBttv';
  static _baseUrlCDN = process.env.REACT_APP_BTTV_CDN_URL;

  static getEmotes = async (channel) => {
    const cachedEmotes = Storage.getWithExpiration(this._cachedEmotesId);
    if (cachedEmotes) {
      return cachedEmotes;
    }

    const idTwitch = await Twitch.getIdBychannelName(channel);
    if (!idTwitch) {
      return;
    }

    const bTTVAPIUrl = this.getApiEndpoint();
    const resBTTV = await Axios.get(
      `${bTTVAPIUrl}/3/cached/users/twitch/${idTwitch}`,
    );
    const channelMappedEmotes = Emote.getMappedEmotes(
      resBTTV.data.channelEmotes,
      this._baseUrlCDN,
    );
    const sharedMappedEmotes = Emote.getMappedEmotes(
      resBTTV.data.sharedEmotes,
      this._baseUrlCDN,
    );
    const mappedEmotes = {
      ...channelMappedEmotes,
      ...sharedMappedEmotes,
    };

    Storage.saveWithExpiration(this._cachedEmotesId, mappedEmotes);
    return mappedEmotes;
  };

  static getApiEndpoint = () => {
    return Helper.isProductionEnvironment()
      ? process.env.REACT_APP_BTTV_API_URL
      : '';
  };
}

export default BTTV;
