class Animation {
  static getAnimationDurationTime = (
    animationInDuration,
    animationOutDuration,
    emojiVisibleTime,
  ) => {
    return (
      (animationInDuration + animationOutDuration + emojiVisibleTime) / 1000 + 2
    );
  };

  static getRandomDirection = () => {
    if (Math.random() * 100 > 50) {
      return 'right';
    }

    return 'left';
  };

  static getUpRandomDirection = () => {
    if (Math.random() * 100 > 50) {
      return ' ';
    }

    return `-${this.getRandomDirection()}`;
  };

  static getRandom = (animationDuration) => {
    if (Math.random() * 100 > 50) {
      return {
        animation: `slideUpKeys${this.getUpRandomDirection()} ${animationDuration}s`,
        animationDelay: '1s',
      };
    }

    return {
      position: 'absolute',
      animation: `bounce 1.5s alternate infinite ease-in, moveX-${this.getRandomDirection()} ${animationDuration}s linear`,
    };
  };

  static getContainerStyle = (animationDuration) => {
    const randomHeight = Math.round(Math.random() * 60 + 40);
    return {
      height: `${randomHeight}vh`,
      animation: `moveY ${animationDuration}s linear`,
    };
  };
}

export default Animation;
