import React from 'react';
import './Banner.css';
import { Animated } from 'react-animated-css';
import { FormattedMessage } from 'react-intl';

function Banner() {
  return (
    <>
      <div className="banner__container" data-testid="banner">
        <Animated animationIn="fadeInUp">
          <h1 className="banner__container__tittle">Memote Wall</h1>
        </Animated>
        <Animated animationIn="fadeInUp" animationInDelay={200}>
          <p className="banner__container__subtitle">
            <FormattedMessage id="Banner.subtitle" />
          </p>
        </Animated>
      </div>
      <div className="twitch"></div>
    </>
  );
}

export default Banner;