import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import './NavBar.css';
import logo from '../assets/images/jmemo162.png';
import twitchLogo from '../assets/images/tiwtch_logo.png';
import instagramIcon from '../assets/icons/instagram_icon.png';
import twitterIcon from '../assets/icons/twitter_icon.png';
import twitchIcon from '../assets/icons/twitch_icon.png';
import { Animated } from 'react-animated-css';

function NavBar() {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <div className="nav-bar" data-testid="navbar">
      <div className="nav-bar__title">
        <a
          href="https://www.twitch.tv/jmemo162"
          alt="jmemo162 on twitch"
          target={'_blank'}
          rel="noreferrer"
        >
          <Animated
            animationInDelay={200}
            animationInDuration={1300}
            animationIn="bounceInLeft"
          >
            <img
              src={twitchLogo}
              className="nav-bar__title__twitch"
              alt="twitch logo"
            />
          </Animated>
          <Animated animationInDuration={1000} animationIn="bounceInRight">
            <img
              className="nav-bar__title__jmemo162"
              src={logo}
              alt="jmemo162"
            />
          </Animated>
        </a>
      </div>

      <div className="nav-bar__right">
        <div className="nav-bar__sections">
          <a
            href="#wtf-is-this"
            className="nav-bar__section press-start-2p-regular"
          >
            <FormattedMessage id="NavBar.wtf" />
          </a>
          <a
            href="#how-to-use"
            className="nav-bar__section press-start-2p-regular"
          >
            <FormattedMessage id="NavBar.howToUse" />
          </a>
          <a
            href="#about-us"
            className="nav-bar__section press-start-2p-regular"
          >
            <FormattedMessage id="NavBar.aboutUs" />
          </a>
        </div>
        <div className="nav-bar__social-icons">
          <a
            href="https://www.instagram.com/jmemo162/"
            alt="instagram"
            target={'_blank'}
            rel="noreferrer"
          >
            <img
              className="nav-bar__social-icons__icon"
              src={instagramIcon}
              alt="instagram"
            />
          </a>
          <a
            href="https://www.twitch.tv/jmemo162"
            alt="twitch"
            target={'_blank'}
            rel="noreferrer"
          >
            <img
              className="nav-bar__social-icons__icon"
              src={twitchIcon}
              alt="twitch"
            />
          </a>
          <a
            href="https://twitter.com/jmemo162"
            alt="twitter"
            target={'_blank'}
            rel="noreferrer"
          >
            <img
              className="nav-bar__social-icons__icon"
              src={twitterIcon}
              alt="twitter"
            />
          </a>
        </div>
      </div>
      {!menuActive && (
        <div className="nav-bar__toggle" onClick={toggleMenu}>
          ☰
        </div>
      )}
      {menuActive && (
        <div className="nav-bar__menu" onClick={toggleMenu}>
          <div className="nav-bar__menu__close">X</div>
          <a
            href="#wtf-is-this"
            className="nav-bar__menu__section press-start-2p-regular"
          >
            <FormattedMessage id="NavBar.wtf" />
          </a>
          <a
            href="#how-to-use"
            className="nav-bar__menu__section press-start-2p-regular"
          >
            <FormattedMessage id="NavBar.howToUse" />
          </a>
          <a
            href="#about-us"
            className="nav-bar__menu__section press-start-2p-regular"
          >
            <FormattedMessage id="NavBar.aboutUs" />
          </a>
          <a
            href="https://www.instagram.com/jmemo162/"
            alt="instagram"
            target={'_blank'}
            rel="noreferrer"
          >
            <img
              className="nav-bar__menu__icon"
              src={instagramIcon}
              alt="instagram"
            />
          </a>
          <a
            href="https://www.twitch.tv/jmemo162"
            alt="twitch"
            target={'_blank'}
            rel="noreferrer"
          >
            <img
              className="nav-bar__menu__icon"
              src={twitchIcon}
              alt="twitch"
            />
          </a>
          <a
            href="https://twitter.com/jmemo162"
            alt="twitter"
            target={'_blank'}
            rel="noreferrer"
          >
            <img
              className="nav-bar__menu__icon"
              src={twitterIcon}
              alt="twitter"
            />
          </a>
        </div>
      )}
    </div>
  );
}

export default NavBar;