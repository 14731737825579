import React from 'react';
import PropTypes from 'prop-types';
import './Teamate.css';

const Teamate = ({ name, link, image }) => {
  return (
    <div className="teamate__container">
      <div
        className="teamate__image"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
      <div className="teamate__name">
        <a href={link} target='blank'>
          <span>{name}</span>
        </a>
      </div>
    </div>
  );
};

Teamate.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string,
};
export default Teamate;
