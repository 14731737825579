import React from 'react';
import './Metrics.css';
import { FormattedMessage } from 'react-intl';

function Metrics() {
  return (
    <div className="metrics">
      <div className="metric-item">
        <img src="/images/kkool.webp" alt="kkool" className="gif-kkool" />
        <h3 className="metrics-title"> + 3K</h3>
        <p className="metrics-description">
          <FormattedMessage id="Metrics.description1" />
        </p>
      </div>
      <div className="metric-item">
        <img
          src="/images/hackersman.webp"
          alt="hackersman"
          className="gif-hackersman"
        />
        <h3 className="metrics-title"> 2 Apps</h3>
        <p className="metrics-description">
          <FormattedMessage id="Metrics.description2" />
        </p>
      </div>
      <div className="metric-item">
        <img
          src="/images/gachihyper.webp"
          alt="gachihyper"
          className="gif-gachihyper"
        />
        <h3 className="metrics-title"> ∞</h3>
        <p className="metrics-description">
          <FormattedMessage id="Metrics.description3" />
        </p>
      </div>
    </div>
  );
}

export default Metrics;
