import React, { createContext, useContext, useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import en from '../locale/en.json';
import es from '../locale/es.json';
import PropTypes from 'prop-types';

const messages = { en, es };

const IntlContext = createContext();

export const MyIntlProvider = ({ children }) => {
  const [lang, setLang] = useState('en');

  useEffect(() => {
    const browserLang = navigator.language.split('-')[0];
    if (messages[browserLang]) {
      setLang(browserLang);
    }
  }, []);

  return (
    <IntlContext.Provider value={[lang, setLang]}>
      <IntlProvider
        messages={messages[lang] || messages.en}
        locale={lang}
        defaultLocale="en"
      >
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};

MyIntlProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useLang = () => useContext(IntlContext);