import '../App.css';
import { useState, useEffect, React } from 'react';
import Emoji from '../components/Emoji';
import BTTV from '../utils/BTTV';
import Twitch from '../utils/twitch';
import Helper from '../utils/helper';
import SevenTv from '../utils/7tv';
import Emote from '../utils/emote';

function Overlay() {
  const [emotes, setEmotes] = useState([]);
  const [isValidHash, setisValidHash] = useState();

  useEffect(() => {
    let bTTVEmotes = [];
    let sevenTvEmotes = [];
    const maxEmotesLength = 100;
    const queryParams = new URLSearchParams(window.location.search);
    const channel = queryParams.get('channel');
    setisValidHash(Helper.validateHash(channel, queryParams.get('hash')));
    (async () => {
      bTTVEmotes = await BTTV.getEmotes(channel);
    })();
    (async () => {
      sevenTvEmotes = await SevenTv.getEmotes(channel);
    })();

    function onMessageHandler(target, tags, msg) {
      const twitchEmotesByTags = Twitch.getEmotesByTags(tags);
      const bttEMotesBymessage = Emote.getEmotesByMessage(msg, bTTVEmotes);
      const seventMotesBymessage = Emote.getEmotesByMessage(msg, sevenTvEmotes);
      let emotesLinks = twitchEmotesByTags.concat(bttEMotesBymessage);
      emotesLinks = emotesLinks.concat(seventMotesBymessage);
      setEmotes((state) => Helper.clearArraByMaxLength(state, maxEmotesLength));
      setEmotes((state) => state.concat(emotesLinks));
    }

    Twitch.connectToChat(channel, onMessageHandler);
  }, []);

  return (
    <div data-testid="overlay">
      {isValidHash ? (
        <div className="memotes" data-testid="memotes">
          {emotes &&
            emotes.map((emoteLink, i) => {
              return <Emoji key={i} link={emoteLink} id={i}></Emoji>;
            })}
        </div>
      ) : (
        <span>Invalid hash</span>
      )}
    </div>
  );
}

export default Overlay;
