import React from 'react';
import { FormattedMessage } from 'react-intl';
import './DonateButton.css';
import gifImage from '../assets/images/3x.webp';
import creditCardsImage from '../assets/images/credit_cards.png';

const DonateButton = () => {
  return (
    <div className="donate-wrapper">
      <div className="image-container">
        <img className="gif-image" src={gifImage} alt="Fun GIF" />
      </div>

      <div className="content-container">
        <h2 className="buyme">
          <FormattedMessage id="DonateButtom.buyMe" />
        </h2>
        <div className="button-container">
          <form
            action="https://www.paypal.com/donate"
            method="post"
            target="_top"
          >
            <input
              type="hidden"
              name="hosted_button_id"
              value="WN5MZDL2D5BVG"
            />
            <button type="submit" className="custom-button">
              <span>
                <FormattedMessage id="DonateButtom.donate" />
              </span>
            </button>
          </form>
        </div>
        <div className="credit-cards-container">
          <img
            className="credit-cards"
            src={creditCardsImage}
            alt="Accepted Credit Cards"
          />
        </div>
      </div>
    </div>
  );
};

export default DonateButton;
